﻿$(document).ready(function () {
    if ($('#tblAsnResults').length) {
        var ct = 0;
        $('#tblAsnResults tfoot th').each(function () {
            if (ct < 5) {
                $(this).html('<div class="input-group"><input type="text" class="input-group__input-box"/></div>');
            }
            ct++;
        });

        $.fn.dataTable.ext.classes.sPageButton = 'fg-button ui-button ui-state-default';

        var resultTable = $('#tblAsnResults').DataTable({
            "initComplete": function() {
                var r = $('#tblAsnResults tfoot tr');
                $('#tblAsnResults thead').append(r);
            },
            "bJQueryUI": true,
            "pagingType": "simple"
        });

        resultTable.columns().eq(0).each(function(colIdx) {
            $('input', resultTable.column(colIdx).footer()).on('keyup change', function() {
                resultTable
                    .column(colIdx)
                    .search(this.value)
                    .draw();
            });
        });

        $("#btnMainSearch").click(function () {
            //resultTable.search($("#inMainSearch").val()).draw();

            $('#divErrorMessage').hide();
            $('#divSearchResults').hide();
            $('.asn-aiport-search-loading').show();

            $('#tblAsnResults').DataTable().clear();

            var request =
            '/api/asnairportsearch/Get/?identifier=' +
                $('#inMainSearchCode').val() +
                '&city=' +
                $('#inMainSearchCity').val() +
                '&state=' +
                $('#inMainSearchState').val() +
                '&airportName=' +
                $('#inMainSearchAirport').val();

            $.getJSON(request)
                .done(function (data) {
                    $.each(data, function (key, item) {
                        var volInfo;

                        if (!item.displayName) {
                            volInfo =
                                "<span class=\"red\">No Volunteer</span>&nbsp;&nbsp;<a href=\"asnnominationform?ap=" +
                                item.airportIdentifier +
                                "\">Sign Up &gt;</a>";
                        } else {
                            volInfo = item.displayName;
                        }

                        var conId;

                        if (item.contactId !== null && item.contactId !== "") {
                            conId = item.contactId;
                        } else {
                            conId = "0";
                        }


                        var reportUrl = "<a href=\"" +
                            $('#EmailURL').val() +
                            "?refno=" +
                            conId +
                            "&ap=" +
                            item.airportAccountId +
                            "&apc=" +
                            item.airportIdentifier +
                            "&ch=" +
                            item.hashKey +
                            "\">Report a Concern &gt;</a>";
                        
                        $('#tblAsnResults').dataTable().fnAddData([
                            item.airportIdentifier,
                            item.airportCity,
                            item.airportStateShort,
                            item.airportName,
                            volInfo,
                            reportUrl
                        ]);
                    });

                    $('#divSearchResults').show();
                    $('.asn-aiport-search-loading').hide();
                })
                .fail(function (jqXHR, textStatus, err) {
                    $('.asn-aiport-search-loading').hide();
                    $('#divErrorMessage').html('There was a problem searching the volunteer information...');
                    $('#divErrorMessage').show();
                });
        });

        $('#inMainSearchCode').keypress(function (event) {
            if (event.keyCode === 13) {
                $('#btnMainSearch').click();
            }
        });
        $('#inMainSearchCity').keypress(function (event) {
            if (event.keyCode === 13) {
                $('#btnMainSearch').click();
            }
        });
        $('#inMainSearchState').keypress(function (event) {
            if (event.keyCode === 13) {
                $('#btnMainSearch').click();
            }
        });
        $('#inMainSearchAirport').keypress(function (event) {
            if (event.keyCode === 13) {
                $('#btnMainSearch').click();
            }
        });

        $('.asn-aiport-search-loading').hide();
        $('#divAsnTable').show();
    }

    $(".asnMessageButton").click(function () {
        var form = $(this).closest('form');
        if (form.valid()) {
            $(this).hide();
            $('.clsSubmit').append('<div class="spinner spinner--clockwise"></div>');
        }
    });
});