export default () => {
  const searchBtn = document.getElementById("search-btn");
  const searchForm = document.getElementById("search-form");
  const closeSearch = document.getElementById("clear-search");

  searchBtn.addEventListener("click", () => {
    searchBtn.classList.toggle("js-search--clicked");

    if (searchBtn.classList.contains("js-search--clicked")) {
      searchBtn.classList.add("hidden");
      searchForm.classList.remove("hidden");
      searchForm.classList.add("header__search--shown");
    } else {
      searchForm.classList.remove("header__search--shown");
      searchForm.classList.add("hidden");
    }
  });

  closeSearch.addEventListener("click", () => {
    if (searchForm.classList.contains("header__search--shown")) {
      searchForm.classList.remove("header__search--shown");
      searchForm.classList.add("hidden");
      searchBtn.classList.remove("hidden");
      searchBtn.classList.remove("js-search--clicked");
    }
  });
};
